@import '../../styles/_variables';

.create-order {
    min-height: 100vh;
    background: $backgraund;
    backdrop-filter: blur(60px);
    &__body {
        padding-top: 50px;
        margin: 0 auto;
        width: 1154px;
        padding-bottom: 50px;
    }
    &__error {
        position: sticky;
        width: 1154px;
        top: 50px;
        z-index: 50;
        div {
            width: 450px;
            margin: 0 auto;
            background: $red;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                margin: 10px;
                color: white;
                font-size: 18px;
            }
        }
    }
    &__nav {
        display: inline-block;
        margin-bottom: 50px;
        font-size: 20px;
        color: $gray-light;
        &:hover {
            color: $gray;
            cursor: pointer;
        }
    }

    //                                                                  hint-input

    .hint-input {
        padding-bottom: 50px;
        .ui.placeholder .line {
            background: none;
        }
        .ui.placeholder {
            max-width: 1000px;
            border-radius: 4px;
        }
        &__subtitle {
            padding-top: 15px;
            margin: 0 0 6px 0;
            color: $gray-dark;
            font-size: 14px;
            font-weight: 500;
        }
    }

    //                                                                                formCreate

    .form-create {
        background: $gradient-white;
        border-radius: 0 0 10px 10px;
        &__body {
            margin: 0 auto;
            min-height: 620px;
            width: 918px;
        }
        &__button {
            color: $gray-dark;
            font-weight: 600;
            font-size: 16px;
            &:hover {
                cursor: pointer;
                color: $black;
            }
        }
        &__container {
            padding-bottom: 84px;
        }
        &__disable {
            cursor: default !important;
            color: $gray-light;
            &:hover {
                color: $gray-light;
            }
        }
        &__сost {
            color: $black;
            font-weight: 600;
            font-size: 16px;
        }
        &__delete {
            margin-top: 43px;
            i {
                color: $gray-dark;
                &:hover {
                    color: $black;
                    cursor: pointer;
                }
            }
        }
        &__model {
            font-weight: 600;
            font-size: 24px;
        }
        &__grid {
            width: 918px;
            margin: 0 auto;
            background: $white;
            padding: 15px;
            box-shadow: 1px 4px 25px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
        }
        &__visible {
            display: flex;
            align-items: center;
            justify-items: center;
            justify-content: center;
            cursor: pointer;
            width: 22px;
            height: 22px;
            border-radius: 20px;
            border: 1px $gray-dark solid;
            color: $gray-dark;
            i {
                margin-left: 3px;
                margin-top: 1px;
            }
        }
        .my-dropdown--active {
            border: 1px solid #96c8da !important;
            border-bottom: 1px solid #ffffff !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
        .my-dropdown--disable {
            opacity: 0.45;
        }
        .my-dropdown {
            position: relative;
            width: 100%;
            border: 1px rgba(34, 36, 38, 0.15) solid;
            border-radius: 0.28571429rem;
            padding: 1px;
            .icon {
                position: absolute;
                top: 4px;
                right: 1px;
            }
            .input {
                border: none;
                width: 100%;
                line-height: 1.21428571em;
                padding: 0.67857143em 2.1em 0.67857143em 1em;
                color: rgba(0, 0, 0, 0.87);
                &:disabled {
                    background: white;
                }
            }
            .option {
                position: absolute;
                width: 638px;
                max-height: 250px;
                overflow: hidden;
                overflow-y: auto;
                z-index: 50;
                left: -1px;
                border: 1px solid #96c8da !important;
                border-top: 1px solid #ffffff !important;
                border-bottom-left-radius: 0.28571429rem !important;
                border-bottom-right-radius: 0.28571429rem !important;
                background: #ffffff;
                &__item {
                    padding: 0.78571429rem 1.14285714rem;
                    white-space: normal;
                    word-wrap: normal;
                    cursor: pointer;
                    &:hover {
                        background: rgba(0, 0, 0, 0.03);
                    }
                }
            }
        }
        &__input {
            display: block;
            width: 100%;
        }
        .ui.input > input {
            width: 100%;
        }
        .ui.selection.dropdown {
            min-width: 0;
        }
        &__subtext {
            padding-bottom: 5px;
        }
        &__text {
            font-size: 20px;
            font-weight: 600;
        }
        &__input-price {
            padding-left: 20px;
            width: 140px;
            bottom: 20px;
        }
        &__input-price {
            padding-left: 20px;
            width: 140px;
            bottom: 0;
        }
        &__number {
            margin-top: 8px;
            height: 38px;
            width: 100%;
        }
        &__stream {
            margin-top: 8px;
            width: 100% !important;
        }
        &__input-second-price {
            padding-left: 20px;
            width: 140px;
            bottom: 20px;
        }
        &__input-name {
            width: 100%;
            margin-bottom: 34px;
        }
    }
}
.modal-popup {
    padding-top: 60px;
    padding-bottom: 40px;
    &__title {
        font-size: 25px;
        color: $dark;
        font-weight: 600;
        margin-bottom: 20px;
    }
    &__row {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }
    &__subtitle {
        font-size: 16px;
        color: $gray-dark;
        font-weight: 500;
    }
    &__text {
        font-size: 16px;
        color: $dark;
        font-weight: 500;
    }
    .ui.placeholder .line {
        background: none;
    }
    .ui.placeholder {
        max-width: 1000px;
        border-radius: 4px;
    }
    .hint-input {
        padding-bottom: 50px;
        &__subtitle {
            padding-top: 15px;
            margin: 0 0 6px 0;
            color: $gray-dark;
            font-size: 14px;
            font-weight: 500;
        }
    }
    pre {
        color: $black-light;
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }
    .status--new {
        width: 17px;
        height: 17px;
        background: #e26443;
        display: inline-block;
        margin-right: 20px;
        border-radius: 50%;
    }
    .status--cut {
        width: 17px;
        height: 17px;
        background: rgba(167, 167, 167, 1);
        display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
    }
    .status--cuter {
        width: 17px;
        height: 17px;
        border: 3px solid rgba(167, 167, 167, 1);
        display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
    }
    .status--inc {
        width: 17px;
        height: 17px;
        background: rgba(190, 166, 114, 1);
        display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
    }
    .status--shop {
        width: 17px;
        height: 17px;
        background: rgba(222, 184, 50, 1);
        display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
    }
    .status--end {
        width: 17px;
        height: 17px;
        background: rgba(33, 186, 69, 1);
        display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
    }
    .status--otc {
        width: 17px;
        height: 17px;
        background: rgba(33, 67, 186, 1);
        display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
    }
}

//                                                        Modal

.modal {
    &__error {
        width: 570px;
        position: fixed;
        background: $red;
        top: 30px;
        border-radius: 5px;
        z-index: 5;
        p {
            text-align: center;
            margin: 10px;
            color: white;
            font-size: 18px;
        }
    }
    &__title {
        font-size: 25px;
        color: $gray-dark;
        font-weight: 600;
    }
    &__title--model {
        padding-bottom: 10px;
        font-size: 18px;
        color: $gray-dark;
        display: block;
    }
    &__text {
        margin: 0 0 6px 0;
        color: $gray-dark;
        font-size: 14px;
        font-weight: 500;
    }
    &__subtitle {
        font-weight: 600;
        font-size: 20px;
    }
    &__name {
        font-size: 30px;
        font-weight: 600;
        padding-bottom: 42px;
    }
    .ui.grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        padding-left: 50px;
        padding-right: 50px;
    }
    &__button {
        margin: 5px;
        padding-bottom: 8px;
        color: $gray-dark;
        font-weight: 600;
        font-size: 16px;
        &:hover {
            cursor: pointer;
            color: $black;
        }
    }
    &__container {
        padding-bottom: 84px;
    }
    p:first-child {
        margin-top: 20px;
    }
    &__сost {
        margin-top: 15px;
        margin-left: 14px;
        color: $black;
        font-weight: 600;
        font-size: 16px;
    }
    &__delete {
        padding-top: 35px;
        i {
            color: $gray-dark;
            &:hover {
                color: $black;
                cursor: pointer;
            }
        }
    }
    &__textarea {
        height: 100px;
        width: 100%;
        padding: 0.67857143em 1em;
        background: #fff;
        border: 1px solid rgba(34, 36, 38, 0.15);
        color: rgba(0, 0, 0, 0.87);
        border-radius: 0.28571429rem;
        transition: box-shadow 0.1s ease, border-color 0.1s ease;
    }
    &__input {
        display: block;
        width: 100%;
    }
    &__icon {
        display: flex;
        cursor: pointer;
        align-items: center;
        padding-top: 10px;
        &:hover {
            opacity: 0.8;
        }
    }
    &__description {
        padding: 0.67857143em 1em;
        background: #fff;
        border: 1px solid rgba(34, 36, 38, 0.15);
        color: rgba(0, 0, 0, 0.87);
        border-radius: 0.28571429rem;
        transition: box-shadow 0.1s ease, border-color 0.1s ease;
        height: 100px;
        width: 100%;
    }
    .ui.input > input {
        width: 100%;
    }
    .ui.selection.dropdown {
        min-width: 0;
    }
}

.modal p:first-child {
    margin-top: 0px;
}

.ui.modal {
    font-size: 1rem;
    width: 1014px;
    min-height: 90%;
}
