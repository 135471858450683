@import '../../styles/_variables';

.setting-page {
    background: $backgraund;
    min-height: 100vh;
    &__body {
        padding-top: 50px;
        margin: 0px auto;
        width: 1154px;
        padding-bottom: 50px;

        //                                                               information

        .information {
            background: $gradient-white;
            border-radius: 0 0 10px 10px;
            &__body {
                margin: 0 auto;
                min-height: 480px;
                width: 918px;
            }
            &__nav{
                padding-bottom: 35px;
                display: flex;
                justify-content: space-between;
            }
            &__text {
                padding-top: 55px;
                font-size: 25px;
                font-weight: 600;
            }
            &__subtext {
                padding-top: 55px;
                font-size: 18px;
                font-weight: 600;
            }
            &__input-price {
                padding-left: 20px;
                width: 140px;
                bottom: 20px;
            }
            &__input-name {
                width: 100%;
                padding-bottom: 28px;
            }
            &__button {
                color: $gray-dark;
                float: right;
                font-size: 20px;
                font-weight: 600;
                margin-top: 85px;
                background: none;
                border: none;
                i {
                    padding-bottom: 32px;
                }
                &:hover {
                    color: $black;
                    cursor: pointer;
                    background: none;
                }
                &:focus {
                    background: none;
                }
            }
            &__button--disabled {
                color: $gray;
                float: right;
                font-size: 20px;
                font-weight: 600;
                margin-top: 85px;
                background: none;
                border: none;
            }
        }

        //                                                              streams

        .streams{
             &__text{
                 color: $black;
                 font-size: 20px;
                 font-weight: 600;
             }
            &__button-add {
                p {
                    color: $gray;
                }
                padding-top: 15px;
                font-size: 16px;
                font-weight: 600;
                background: none;
                border: none;
                &:hover {
                    p{
                        color: $gray-dark;
                    }
                    cursor: pointer;
                }
            }
            &__button-delete {
                font-size: 16px;
                border: none;
                background: none;
                float: right;
                color: $gray;
                &:hover {
                    color: $gray-dark;
                    cursor: pointer;
                }
            }
            &__name {
                padding-top: 16px;
                padding-bottom: 10px;
                height: 76px;
                width: 100%;
            }
        }

        //                                                          headerWizard

        .heder {
            &__steeper_title{
               &:hover{
                   cursor: pointer;
               }
            }
            background: $gradient-black;
            border-radius: 10px 10px 0 0;
            &__body {
                height: 200px;
                margin: 0 100px 0 100px;
            }
            &__title {
                font-size: 30px;
                color: $white;
                padding-top: 50px;
                padding-bottom: 20px;
            }
            &__stepper{
                margin: 0 180px 0 180px;
            }
        }
    }
}
