@import '../../styles/_variables.scss';

.modal-delete {
    padding-top: 60px;
    &__button {
        width: 140px !important;
        height: 40px !important;
        display: none;
    }
}

.ui.modal {
    min-height: 385px !important;
    min-width: 500px;
}
