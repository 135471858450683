@import '../../styles/_variables';

.costs {
    min-height: 100vh;
    &__body{
        padding-bottom: 50px;
    }
    &__table{
        width: 70%;
    }
    i.icon.costs__img{
        margin-left: 20px;
        &:hover{
            cursor: pointer;
        }
    }
    &__img{
        float: right;
    }
    &__title{
        padding-top: 20px;
        color: $gray-light;
    }
    .filter{
        margin-top: 37px;
        &__input{
            width: 100%;
        }
        &__text{
            color: $gray-light;
            font-size: 14px;
        }
        &__title{
            font-size: 30px;
            display: inline;
            font-weight: 700;
            color: $white;
            padding-right: 30px;
        }
        &__button{
            font-size: 28px;
            background: none;
            border: none;
            margin-right: 25px;
            p{
                font-weight: 400;
                color: $gold;
            }
            &:hover{
                p{
                    color: $white;
                }
                cursor: pointer;
            }
        }
    }

}
