@import '../../styles/_variables';

.order-timeline {
    min-height: 100vh;
    &__body{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .ui.segment{
        padding: 0;
    }
    .ui.table{
        margin: 0;
    }
    .table{
        background: $white;
        border-radius: 10px;
        min-width: 100%;
        min-height: 200px;
        overflow-x: auto;
        &__target{
            box-shadow: 0 2px 3px $gray-dark;
        }
        &__cell{
            text-align: center;
            min-width: 49px;
            padding-top: 15px;
        }
        &__columns{
            width: 49px;
            height: 60px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        &__stream{
            color: $gray-light;
            margin-left: 2px;
            border-radius: 5px;
            font-size: 20px;
            font-weight: 600;
        }
        .stream{
            padding-top: 20px;
            padding-left: 12px;
            min-width: 98px;
        }
        &__body{
            border-top: 1px $gray-light solid;
        }
        &__title{
            background: linear-gradient(180deg, #FCFCFC 0%, #F5F5F5 100%);
            border-bottom: 1px $gray-dark solid;
            min-width: 49px;
        }
        &__dnd--end{
            width: 49px;
            height: 60px;
            padding-top: 5px;
            padding-bottom: 5px;
            border-left: 2px $gray-dark solid;
        }
        &__dnd--active{
            text-align: center;
            width: 49px;
            height: 60px;
            padding-top: 5px;
            padding-bottom: 5px;
            border-left: $green 2px dashed ;
        }
        &__dnd{
            z-index: 1;
            text-align: center;
            width: 49px;
            height: 45px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        &__item{
            z-index: 5;
           &:hover{
               cursor: pointer;
           }
           border-radius: 10px;
           max-height: 35px;
           padding: 8px;
           margin-left: 1px;
           margin-right: 1px;
            span{
              font-weight: 600;
              font-size: 14px;
           }
        }
        .blue{
            background: rgba(33, 67, 186, 0.1);
            span{
                color: $blue;
            }
        }
        .blue-bright{
            background: $blue;
            span{
                color: $white;
            }
        }
        .pink{
             background: rgba(155, 33, 186, 0.1);
            span{
                color: $pink;
            }
        }
        .pink-bright{
            background: $pink;
            span{
                color: $white;
            }
        }
        .azure{
            background: rgba(33, 177, 186, 0.1);
            span{
                color: $azure;
            }
        }
        .azure-bright{
            background: $azure;
            span{
                color: $white;
            }
        }
        &__list{
            display: grid;
            grid-template-columns: repeat(10, 1fr);
        }
        &__item--start{
            z-index: 5;
            &:hover{
                cursor: pointer;
            }
            border-radius: 10px 0 0 10px;
            max-height: 35px;
            padding: 8px;
            margin-left: 1px;
            margin-right: 1px;
            span{
                font-weight: 600;
                font-size: 14px;
            }
        }
        &__item--end{
            z-index: 5;
            &:hover{
                cursor: pointer;
            }
            border-radius: 0 10px 10px 0;
            max-height: 35px;
            padding: 8px;
            margin-left: 1px;
            margin-right: 1px;
            span{
                font-weight: 600;
                font-size: 14px;
            }
        }
        &__disable{
            background: linear-gradient(180deg, #FCFCFC 0%, #F5F5F5 100%);
            .table__day{
                font-size: 14px;
                font-weight: 500;
                padding: 12px 17px 4px 17px;
                border-left: 1px $gray-light solid;
                color: $gray-light;
            }
        }
        &__active{
            border-left: 2px $green dashed;
        }
        &__end{
            border-left: 2px $gray-dark solid;
            .table__day{
                font-size: 14px;
                font-weight: 500;
                padding: 12px 17px 4px 17px;
                border-left: none;
            }
        }
        &__month{
            color: $black-light;
            font-size: 12px;
            font-weight: 600;
            position: absolute;
            width: 100px;
        }
        &__day{
            font-size: 14px;
            font-weight: 500;
            padding: 12px 17px 4px 17px;
            border-left: 1px $gray-light solid;
        }
        &__day.active{
            color: $white;
            background: #21BA45;
            border-left :none;
            :disabled{
                min-height: 100%;
            }
        }
    }
    .ui.container {
        display: inherit;
        max-width: 100%!important;
        margin-left: 0!important;
    }
    .filter{
        margin-top: 37px;
        &__input{
            width: 80%;
        }
        &__title{
            padding-bottom: 20px;
            padding-top: 25px;
            font-size: 30px;
            font-weight: 600;
            color: $white;
        }
        &__text{
            color: $gray-light;
            font-size: 14px;
        }
    }
    .ui.grid>.row {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
}
.drag-ghost {
    width: 200px;
    height: 35px;
    border-radius: 10px;
    background-color: $gray-light;
}
