@import "../../styles/_variables.scss";

.header{
    background: $gradient-black;
    border-radius: 10px 10px 0 0;
    &__body{
        height: 180px;
        padding: 40px 112px 0 112px;
        display: flex;
        justify-content: space-between;
    }
    &__button{
        width: 52px;
    }
    &__title{
        color: $white;
        font-size: 30px;
        font-weight: 600;
    }
    pre{
        color: $gray-light;
        font-size: 18px;
        font-weight: 600;
    }
    .status--new{
        width: 17px;
        height: 17px;
        background: #e26443;
        display: inline-block;
        margin-right: 20px;
        border-radius: 50%;
    }
    .status--cut{
        width: 17px;
        height: 17px;
        background: rgba(167, 167, 167, 1);
        display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
    }
    .status--cuter{
        width: 17px;
        height: 17px;
        border: 3px solid rgba(167, 167, 167, 1);
        display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
    }
    .status--inc{
        width: 17px;
        height: 17px;
        background: rgba(190, 166, 114, 1);
        display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
    }
    .status--shop{
        width: 17px;
        height: 17px;
        background: rgba(222, 184, 50, 1);
        display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
    }
    .status--end{
        width: 17px;
        height: 17px;
        background: rgba(33, 186, 69, 1);
        display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
    }
    .status--otc{
        width: 17px;
        height: 17px;
        background: rgba(33, 67, 186, 1);
        display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
    }
}