$gradient-black: linear-gradient(211.09deg, #635d58 5.72%, #22211d 84.13%);
$dark: #22201d;
$black: #3a3832;
$backgraund: linear-gradient(198.19deg, #443939 19.34%, #221d1d 88.36%);
$black-dark: rgba(34, 32, 29, 1);
$black-light: #62605A;

$gradient-white: linear-gradient(180deg, #fcfcfc 0%, #f5f5f5 100%);
$gray: #b2b0aa;
$gray-light: #d0cec8;
$gray-dark: #94928c;

$white: #ffffff;

$gold: #e2d0a8;

$pink: #9B21BA;
$azure: #21B1BA;
$blue: #2143BA;
$red: #e53232;
$green: #2bcb58;
