@import '../../styles/_variables';

.model-table {
    .filter {
        margin-top: 37px;
        padding-bottom: 14px;
        &__title {
            font-size: 30px;
            display: inline;
            font-weight: 700;
            color: $white;
            padding-right: 30px;
        }
        &__input {
            width: 100%;
        }
        &__text {
            color: $gray-light;
            font-size: 14px;
        }
        .row.filter__row {
            padding-top: 20px;
        }
        &__button {
            font-size: 28px;
            background: none;
            border: none;
            margin-right: 25px;
            p {
                font-weight: 400;
                color: $gold;
            }
            &:hover {
                p {
                    color: $white;
                }
                cursor: pointer;
            }
        }
    }

    //                                                          Modal
    .modal {
        margin: 120px;
        &__check {
            margin: 50px;
        }
        &__input {
            display: block;
            width: 100%;
        }
        &__button {
            margin: 10px;
            padding-bottom: 8px;
            color: $gray-dark;
            font-weight: 600;
            &:hover {
                cursor: pointer;
                color: $black;
            }
        }
    }
}
.modal p:first-child {
    margin-top: 6px;
}

.ui.modal {
    min-height: 463px;
}

.ui[class*='left icon'].input > input {
    padding-left: 2.67142857em !important;
    padding-right: 55px !important;
}

/*******************************
           Checkbox
*******************************/

/* Handle */

.ui.toggle.checkbox .box,
.ui.toggle.checkbox label {
    font-size: 16px;
    font-weight: 600;
}

.ui.toggle.checkbox input:checked ~ .box,
.ui.toggle.checkbox input:checked ~ label {
    color: $green !important;
}
.ui.toggle.checkbox input:focus:checked ~ .box,
.ui.toggle.checkbox input:focus:checked ~ label {
    color: $green !important;
}
.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
    background: #21ba45 -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.05)));
    background: #21ba45 -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
    background: #21ba45 linear-gradient(transparent, rgba(0, 0, 0, 0.05));
    position: absolute;
    content: '' !important;
    opacity: 1;
    z-index: 2;
    border: none;
    -webkit-box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15),
        0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
    width: 1.5rem;
    height: 1.5rem;
    top: 0rem;
    left: 0em;
    border-radius: 500rem;
    -webkit-transition: background 0.3s ease, left 0.3s ease;
    transition: background 0.3s ease, left 0.3s ease;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #cfcfcf !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #cfcfcf !important;
}
