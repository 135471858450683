@import '../../styles/_variables';

.authorization {
    background-image: url('../../img/authorization.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    &__body {
        padding-top: 7%;
        padding-bottom: 1%;
        margin: 0 auto;
        width: 585px;
    }

    //                                                                    header

    .authorization-heder {
        background: $gradient-black;
        border-radius: 10px 10px 0 0;
        height: 162px;
        &__body {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding-top: 60px;
            margin: 0 20px 0 50px;
        }
        &__nav {
            padding-top: 12px;
        }
        &__title {
            font-size: 19px;
            color: $gray-light;
            &:hover {
                color: $gray;
                cursor: pointer;
            }
        }
        &__logo {
            width: 250px;
            height: 50px;
            background: url('../../img/logo.png') no-repeat;
        }
    }

    //                                                                      from

    .form_auth {
        height: 568px;
        background: $white;
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.4);
        border-radius: 0px 0px 10px 10px;
        margin-bottom: 50px;
        &__body {
            width: 445px;
            margin: 0 auto;
            text-align: center;
        }
        &__title {
            float: left;
            padding-top: 40px;
            padding-bottom: 30px;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 36px;
        }
        &__dropdown {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 28px;
            span {
                margin-left: 38px;
            }
        }
        i.icon {
            position: absolute;
            margin: 0 18px 0 -6px;
        }
        &__input {
            height: 72px;
            font-size: 24px;
        }
        .ui.massive.basic.button.form_auth__button {
            width: 100%;
            height: 72px;
        }
        .ui.massive.basic.disabled.button.form_auth__button {
            border: 1px solid;
        }
        &__error {
            width: 445px;
            position: fixed;
            background: $red;
            top: 50px;
            border-radius: 5px;
            p {
                margin: 10px;
                color: white;
                font-size: 18px;
            }
        }
        &__hr {
            transform: translateX(-12%);
            width: 583px;
            margin-top: 56px;
            background: $gray-light;
        }
        &__nav {
            text-align: left;
            margin-top: 38px;
        }
        &__icon {
            float: right;
        }
        &__text {
            font-size: 28px;
            color: $gray-dark;
            &:hover {
                cursor: pointer;
                color: $black;
            }
        }
    }
}
