@import '../../styles/_variables';

.wizard {
    background: $backgraund;
    min-height: 100vh;
    &__body {
        padding-top: 50px;
        margin: 0px auto;
        width: 1154px;
        padding-bottom: 50px;

        //                                                                reoleAndPersonal

        .roles-and-personnel {
            background: $gradient-white;
            border-radius: 0 0 10px 10px;
            &__body {
                margin: 0 auto;
                min-height: 550px;
                width: 918px;
            }
            &__cheak {
                margin-right: 15px;
            }
            &__text {
                display: inline-block;
                span {
                    font-size: 20px;
                    font-weight: 600;
                }
            }
            &__error {
                width: 450px;
                position: fixed;
                background: $red;
                top: 50px;
                left: 38%;
                border-radius: 5px;
                z-index: 1;
                text-align: center;
                p {
                    margin: 10px;
                    color: white;
                    font-size: 18px;
                }
            }
            &__title {
                padding-top: 50px;
                padding-bottom: 20px;
                font-size: 26px;
                font-weight: 600;
            }
            &__hr {
                margin-bottom: 16px;
                margin-top: 31px;
                background: $gray-light;
            }
            &__nav {
                display: flex;
                justify-content: space-between;
                padding-bottom: 40px;
                padding-top: 25px;
            }
            &__button {
                color: $gray-dark;
                float: right;
                font-size: 20px;
                font-weight: 600;
                background: none;
                border: none;
                i {
                    padding-bottom: 32px;
                }
                &:hover {
                    color: $black;
                    cursor: pointer;
                    background: none;
                }
                &:focus {
                    background: none;
                }
            }
            &__button-add {
                p {
                    color: $gray;
                }
                padding-top: 15px;
                font-size: 16px;
                font-weight: 600;
                background: none;
                border: none;
                &:hover {
                    cursor: pointer;
                }
            }
            &__button-delete {
                color: $gray;
                &:hover {
                    cursor: pointer;
                }
            }
            &__block-input {
                padding-top: 23px;
                padding-left: 55px;
            }
            &__fio {
                width: 400px;
                height: 50px;
            }
            &__stream {
                font-size: 19px;
                margin: 0 10px 10px 10px;
            }
            &__kod {
                padding-right: 16px;
                width: 140px;
                height: 50px;
            }
            &__kod--margin {
                padding-right: 16px;
                width: 140px;
                height: 50px;
                margin-left: 10px;
                margin-top: 10px;
            }
        }

        /*******************************
               Checkbox
    *******************************/

        /* Handle */
        .ui.toggle.checkbox .box:after,
        .ui.toggle.checkbox label:after {
            background: #21ba45 -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.05)));
            background: #21ba45 -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
            background: #21ba45 linear-gradient(transparent, rgba(0, 0, 0, 0.05));
            position: absolute;
            content: '' !important;
            opacity: 1;
            z-index: 2;
            border: none;
            -webkit-box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15),
                0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
            box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15),
                0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
            width: 1.5rem;
            height: 1.5rem;
            top: 0rem;
            left: 0em;
            border-radius: 500rem;
            -webkit-transition: background 0.3s ease, left 0.3s ease;
            transition: background 0.3s ease, left 0.3s ease;
        }

        .ui.toggle.checkbox input:checked ~ .box:before,
        .ui.toggle.checkbox input:checked ~ label:before {
            background-color: #cfcfcf !important;
        }

        .ui.toggle.checkbox input:focus:checked ~ .box:before,
        .ui.toggle.checkbox input:focus:checked ~ label:before {
            background-color: #cfcfcf !important;
        }

        //                                                               information

        .information {
            background: $gradient-white;
            border-radius: 0 0 10px 10px;
            &__body {
                margin: 0 auto;
                height: 550px;
                width: 918px;
            }
            &__title {
                padding-top: 76px;
                padding-bottom: 71px;
                font-size: 26px;
                font-weight: 600;
            }
            &__text {
                font-size: 20px;
                font-weight: 600;
            }
            &__input-price {
                padding-left: 20px;
                width: 140px;
                bottom: 20px;
            }
            &__input-name {
                width: 100%;
                padding-bottom: 28px;
            }
            &__button {
                color: $gray-dark;
                float: right;
                font-size: 20px;
                font-weight: 600;
                margin-top: 85px;
                background: none;
                border: none;
                i {
                    padding-bottom: 32px;
                }
                &:hover {
                    color: $black;
                    cursor: pointer;
                    background: none;
                }
                &:focus {
                    background: none;
                }
            }
            &__button--disabled {
                color: $gray;
                float: right;
                font-size: 20px;
                font-weight: 600;
                margin-top: 85px;
                background: none;
                border: none;
            }
        }

        //                                                                    endSetting

        .end-setting {
            height: 100vh;
            &__body {
                padding-top: 15%;
                margin: 0px auto;
                width: 1154px;
                text-align: center;
                background: $gradient-black;
                border-radius: 10px;
                height: 780px;
            }
            &__text {
                padding-top: 15px;
                display: inline-block;
                color: $gray-light;
                font-size: 20px;
                font-weight: 600;
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                }
            }
            &__title {
                padding-top: 30px;
                font-size: 30px;
                font-weight: 600;
                color: $white;
            }
            &__button {
                width: 150px;
                height: 50px;
            }
            &__img {
                padding-top: 11px;
                min-width: 78px;
                min-height: 78px;
                border-radius: 50%;
                background: white;
            }
        }

        //                                                                              customers

        .customers {
            background: $gradient-white;
            border-radius: 0 0 10px 10px;
            &__body {
                margin: 0 auto;
                min-height: 550px;
                width: 918px;
            }
            &__title {
                padding-top: 76px;
                padding-bottom: 71px;
                font-size: 26px;
                font-weight: 600;
            }
            &__nav {
                display: flex;
                justify-content: space-between;
                padding-bottom: 40px;
            }
            &__button {
                color: $gray-dark;
                float: right;
                font-size: 20px;
                font-weight: 600;
                margin-top: 85px;
                background: none;
                border: none;
                i {
                    padding-bottom: 32px;
                }
                &:hover {
                    color: $black;
                    cursor: pointer;
                    background: none;
                }
                &:focus {
                    background: none;
                }
            }
            &__error {
                width: 450px;
                position: fixed;
                background: $red;
                top: 10%;
                left: 38%;
                border-radius: 5px;
                z-index: 1;
                text-align: center;
                p {
                    margin: 10px;
                    color: white;
                    font-size: 18px;
                }
            }
            &__text {
                color: $black;
                font-size: 20px;
                font-weight: 600;
            }
            &__description {
                padding: 0.67857143em 1em;
                background: #fff;
                border: 1px solid rgba(34, 36, 38, 0.15);
                color: rgba(0, 0, 0, 0.87);
                border-radius: 0.28571429rem;
                transition: box-shadow 0.1s ease, border-color 0.1s ease;
                font-size: 20px;
                height: 100px;
                width: 100%;
            }
            &__name {
                padding-top: 16px;
                padding-bottom: 10px;
                height: 76px;
                width: 100%;
            }
            &__button-delete {
                font-size: 16px;
                border: none;
                background: none;
                float: right;
                color: $gray;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        //                                                          headerWizard

        .heder {
            background: $gradient-black;
            border-radius: 10px 10px 0 0;
            &__body {
                height: 202px;
                padding: 40px 112px 0 112px;
            }
            &__title {
                font-size: 30px;
                color: $white;
                padding-bottom: 15px;
            }
        }
    }

    //                                                                              Streams

    .streams {
        background: $gradient-white;
        border-radius: 0 0 10px 10px;
        &__body {
            margin: 0 auto;
            min-height: 550px;
            width: 918px;
        }
        &__title {
            padding-top: 76px;
            padding-bottom: 71px;
            font-size: 26px;
            font-weight: 600;
        }
        &__nav {
            display: flex;
            justify-content: space-between;
            padding-bottom: 40px;
        }
        &__button {
            color: $gray-dark;
            float: right;
            font-size: 20px;
            font-weight: 600;
            margin-top: 85px;
            background: none;
            border: none;
            i {
                padding-bottom: 32px;
            }
            &:hover {
                color: $black;
                cursor: pointer;
                background: none;
            }
            &:focus {
                background: none;
            }
        }
        &__error {
            width: 450px;
            position: absolute;
            background: $red;
            top: 10%;
            left: 38%;
            border-radius: 5px;
            z-index: 1;
            text-align: center;
            p {
                margin: 10px;
                color: white;
                font-size: 18px;
            }
        }
        &__text {
            color: $black;
            font-size: 20px;
            font-weight: 600;
        }
        &__description {
            padding: 0.67857143em 1em;
            background: #fff;
            border: 1px solid rgba(34, 36, 38, 0.15);
            color: rgba(0, 0, 0, 0.87);
            border-radius: 0.28571429rem;
            transition: box-shadow 0.1s ease, border-color 0.1s ease;
            font-size: 20px;
            height: 100px;
            width: 100%;
        }
        &__name {
            padding-top: 16px;
            padding-bottom: 10px;
            height: 76px;
            width: 100%;
        }
        &__button-delete {
            font-size: 16px;
            border: none;
            background: none;
            float: right;
            color: $gray;
            &:hover {
                cursor: pointer;
            }
        }
        &__button-add {
            p {
                color: $gray;
            }
            padding-top: 15px;
            font-size: 16px;
            font-weight: 600;
            background: none;
            border: none;
            &:hover {
                cursor: pointer;
            }
        }
    }
}
