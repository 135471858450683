@import '../../styles/_variables';

.order-distribution {
    min-height: 100vh;
    background: $black-dark;
    backdrop-filter: blur(60px);
    &__body {
        padding-top: 50px;
        margin: 0 auto;
        width: 1154px;
        padding-bottom: 50px;
    }
    &__nav {
        display: inline-block;
        margin-bottom: 50px;
        font-size: 20px;
        color: $gray-light;
        &:hover {
            color: $gray;
            cursor: pointer;
        }
    }

    //                                                                                formCreate

    .form-distribution {
        background: $gradient-white;
        border-radius: 0 0 10px 10px;
        &__body {
            margin: 0 auto;
            min-height: 620px;
            width: 918px;
        }
        &__error {
            right: 35%;
            width: 570px;
            position: absolute;
            background: $red;
            top: 4%;
            border-radius: 5px;
            p {
                text-align: center;
                margin: 10px;
                color: white;
                font-size: 18px;
            }
        }
        &__button {
            color: $gray-dark;
            font-weight: 600;
            font-size: 16px;
            &:hover {
                cursor: pointer;
                color: $black;
            }
        }
        &__container {
            padding-bottom: 84px;
        }
        &__delete {
            margin-top: 28px;
            i {
                color: $gray-dark;
                &:hover {
                    color: $black;
                    cursor: pointer;
                }
            }
        }
        &__container {
            padding-bottom: 46px;
        }
        &__grid {
            width: 918px;
            margin: 0 auto;
            background: $white;
            padding: 15px;
            box-shadow: 1px 4px 25px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
        }
        &__model-name {
            font-size: 16px;
            font-weight: 600;
        }
        &__input {
            width: 60%;
        }
        .status {
            border-radius: 100%;
            height: 20px;
            width: 20px;
            background: #bea672;
            border-right: 2px solid #bea672;
            display: inline-block;
            margin-right: 10px;
            transform: rotate(0.1turn);
            &__not-full {
                border-top: 2px dashed #bea672;
                border-left: 2px dashed #bea672;
                border-bottom: 2px dashed #bea672;
                border-top-left-radius: 40px;
                border-bottom-left-radius: 40px;
                background-color: $white;
                width: 50%;
                height: 100%;
            }
        }
        &__select {
            width: 100%;
        }
        .ui.input > input {
            width: 100%;
        }
        .ui.selection.dropdown {
            min-width: 0;
        }
        &__default {
            display: block;
            width: 100%;
            height: 15px;
            margin-top: 6px;
            background: rgba(196, 196, 196, 0.4);
            border-radius: 4px;
        }
        &__section {
            padding-top: 36px;
            padding-bottom: 16px;
            font-size: 26px;
            font-weight: 600;
        }
        &__dropdown {
            float: right;
            font-size: 16px;
            color: $black;
            font-weight: 600;
        }
        &__icon {
            padding-left: 9px;
            color: #e2d0a8;
        }
        &__row--title {
            &:hover {
                cursor: pointer;
            }
        }

        &__title {
            padding-top: 36px;
            padding-bottom: 16px;
            font-size: 24px;
            font-weight: 600;
            text-decoration: underline;
        }
        &__subtitle {
            color: $gray-dark;
            font-weight: 400;
            font-size: 16px;
        }
        &__button-add {
            p {
                color: $gray;
            }
            padding-top: 15px;
            font-size: 16px;
            font-weight: 600;
            background: none;
            border: none;
            &:hover {
                p {
                    color: $gray-dark;
                }
                cursor: pointer;
            }
        }
        &__сost {
            padding-top: 5px;
            color: $black;
            font-weight: 600;
            font-size: 16px;
        }
        .ui.grid > .row.form-distribution__row {
            padding-bottom: 0.3rem;
        }
        .ui.grid > .row.form-distribution__row--title {
            padding-bottom: 0.3rem;
        }
        .ui.grid > .row.form-distribution__row-body {
            padding-top: 0;
            padding-bottom: 1rem;
        }
        &__text {
            font-size: 20px;
            font-weight: 600;
        }
        &__input-price {
            padding-left: 20px;
            width: 140px;
            bottom: 20px;
        }
        &__input-name {
            width: 100%;
            margin-bottom: 34px;
        }
    }
}

.modal__subtext {
    color: $black-dark;
    font-size: 14px;
}

.modal__textfield {
    margin-top: 5px;
}

.modal__errorSelection {
    width: 468px;
    position: fixed;
    background: $red;
    bottom: 90px;
    z-index: 1;
    border-radius: 5px;
    padding: 10px;
    color: white;
    font-size: 18px;
    text-align: center;
    margin: 0 1rem;
}

.modal__section {
    border: 1px $black-light solid;
    border-radius: 25px;
    margin: 0 1rem;
    position: relative;
}

.modal__sectionText {
    font-size: 16px;
    font-weight: 600;
    width: 468px;
    padding: 5px 1rem;
}

.modal__sectionTextAlt {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 468px;
    border-bottom: 1px $black-light solid;
    padding: 5px 1rem;
    margin: 0 1rem;
}

.modal__sectionDeleteAlt {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}

.modal__sectionDelete {
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}
