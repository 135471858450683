* {
    padding: 0;
    margin: 0;
    list-style: none;
    outline: none;
    font-family: 'Proxima Nova', Roboto, system-ui, Tahoma;
    box-sizing: border-box;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: black;
}

a,
span,
p,
b,
h1,
h2,
h3,
h4,
h5 {
    color: black;
}

h1 {
    font-size: 48px;
}

h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
}

a {
    text-decoration: none;
}

@mixin noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
