@import '../../styles/_variables';

.table-order {
    &__table.ui.basic.table {
        background-color: $white;
    }
    &__row {
        cursor: pointer;
    }
    i.icon.table-order__img {
        margin-left: 20px;
        &:hover {
            cursor: pointer;
        }
    }
    &__img {
        float: right;
    }
    .status--new {
        width: 13px;
        height: 13px;
        background: #e26443;
        display: inline-block;
        margin-right: 7px;
        border-radius: 50%;
    }
    .status--cut {
        width: 13px;
        height: 13px;
        background: rgba(167, 167, 167, 1);
        display: inline-block;
        margin-right: 7px;
        border-radius: 50%;
    }
    .status--cuter {
        width: 13px;
        height: 13px;
        border: 3px solid rgba(167, 167, 167, 1);
        display: inline-block;
        margin-right: 7px;
        border-radius: 50%;
    }
    .status--shop {
        width: 13px;
        height: 13px;
        background: rgba(222, 184, 50, 1);
        display: inline-block;
        margin-right: 7px;
        border-radius: 50%;
    }
    .status--inc {
        width: 13px;
        height: 13px;
        background: rgba(190, 166, 114, 1);
        display: inline-block;
        margin-right: 7px;
        border-radius: 50%;
    }
    .status--end {
        width: 13px;
        height: 13px;
        background: rgba(33, 186, 69, 1);
        display: inline-block;
        margin-right: 7px;
        border-radius: 50%;
    }
    .status--otc {
        width: 13px;
        height: 13px;
        background: rgba(33, 67, 186, 1);
        display: inline-block;
        margin-right: 7px;
        border-radius: 50%;
    }
    .filter {
        margin-top: 37px;
        &__title {
            font-size: 30px;
            display: inline;
            font-weight: 700;
            color: $white;
            padding-right: 30px;
        }
        &__input {
            width: 100%;
        }
        &__inputDate {
            width: 240px;
        }
        &__text {
            color: $gray-light;
            font-size: 14px;
        }
        .row.filter__row {
            padding-top: 20px;
        }
        &__button {
            font-size: 28px;
            background: none;
            border: none;
            margin-right: 25px;
            p {
                font-weight: 400;
                color: $gold;
            }
            &:hover {
                p {
                    color: $white;
                }
                cursor: pointer;
            }
        }
    }
}
