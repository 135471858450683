@import '../../styles/_variables';

.report {
    min-height: 100vh;
    &__title{
        padding-top: 20px;
        color: $gray-light;
    }
    &__pagination{
        margin-bottom: 25px;
    }
    &__nav{
        display: inline-block;
        padding-right: 25px;
    }
    &__table-main{
        width: 60%;
    }
    &__section{
        padding-top: 30px;
    }
    &__table{
        overflow-x: scroll;
    }
    &__chart{
        padding-top: 10px;
    }
    &__body{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .filter{
        &__nav{
            display: inline-block;
            font-size: 20px;
            color: $gray-light;
            &:hover{
                color: $gray;
                cursor: pointer;
            }
        }
        &__title{
            padding-top: 10px;
            font-size: 30px;
            font-weight: 600;
            color: $white;
        }
        &__input{
            width: 100%;
        }
        &__text{
            color: $gray-light;
            font-size: 14px;
        }
    }
}