@import '../../styles/_variables';

.salary {
    min-height: 100vh;
    &__body{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &__table{
        padding-top: 8px;
        width: 100%;
        p{
            font-weight: 700;
        }
    }
    &__title{
        padding-top: 20px;
        color: $gray-light;
    }
    .filter{
        margin-top: 37px;
        &__title{
            font-size: 30px;
            display: inline;
            font-weight: 700;
            color: $white;
            padding-right: 30px;
        }
        &__input{
            width: 80%;
        }
        &__text{
            color: $gray-light;
            font-size: 14px;
        }
    }
}
