@import '../../styles/_variables';

.profitability {
    &__title{
        padding-top: 20px;
        color: $gray-light;
    }
    &__title-page{
        font-size: 30px;
        display: inline;
        font-weight: 700;
        color: $white;
        padding-right: 30px;
    }
    &__pagination{
        margin-top: 10px;
        margin-bottom: 25px;
    }
    &__nav{
        display: inline-block;
        padding-right: 25px;
    }
    &__button{
        min-width: 60px;
    }
    .ui.basic.inverted.button.profitability__button--active{
        min-width: 60px;
        background: $white !important;
        color: $black !important;
        font-weight: 600;
    }
    .ui.button:not(.icon)>.right.icon:not(.button):not(.dropdown) {
         margin: 0;
    }
    .ui.button:not(.icon)>.icon:not(.button):not(.dropdown) {
        margin: 0;
    }
    &__table-secondary{
        width: 40%;
    }
    &__table-main{
        p{
            font-weight: 700;
        }
    }
    &__table-secondary--last{
        width: 30%;
        padding-bottom: 20px;
    }
    &__body{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .filter{
        &__nav{
            display: inline-block;
            font-size: 20px;
            color: $gray-light;
            &:hover{
                color: $gray;
                cursor: pointer;
            }
        }
        &__input{
            width: 100%;
        }
        &__text{
            color: $gray-light;
            font-size: 14px;
        }
    }
}
